var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_c('h4',{staticClass:"font-weight-light"},[_vm._v("SECURITY DEPOSITS (OFFICE/CHAPEL RENTAL)")])])],1),_c('v-layout',{staticClass:"align-center mx-2 mt-5",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"mx-2",attrs:{"xs12":"","md12":""}},[_c('v-tabs',{attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.title},[_c('v-icon',{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('h2',[_vm._v("Total: P "+_vm._s(_vm.total_amount))]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.data_items,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._v(" LEGENDS - (DISSOLVE- "),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCastOff)+" ")]),_vm._v(" ), (FOR REFUND- "),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCashRefund)+" ")]),_vm._v(" ), (CONSUMED- "),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiShieldCheck)+" ")]),_vm._v(" ), (EDIT BUILDING OWNER- "),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_vm._v(" ) ")]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.branch_code)+" ")]),_c('td',[_c('inner_data',{attrs:{"headers":_vm.headers2,"items":item.office_rental_sec_deposits,"status":1},on:{"data":function($event){return _vm.initialize_data()}}})],1)])]}}])})],1),_c('v-tab-item',[_c('h2',[_vm._v("Total: P "+_vm._s(_vm.total_amount2))]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.data_items2,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.branch_code)+" ")]),_c('td',[_c('inner_data',{attrs:{"headers":_vm.headers2,"items":item.office_rental_sec_deposits,"status":2},on:{"data":function($event){return _vm.initialize_data()}}})],1)])]}}])})],1),_c('v-tab-item',[_c('h2',[_vm._v("Total: P "+_vm._s(_vm.total_amount3))]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.data_items3,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.branch_code)+" ")]),_c('td',[_c('inner_data',{attrs:{"headers":_vm.headers2,"items":item.office_rental_sec_deposits,"status":3},on:{"data":function($event){return _vm.initialize_data()}}})],1)])]}}])})],1),_c('v-tab-item',[_c('h2',[_vm._v("Total: P "+_vm._s(_vm.total_amount4))]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.data_items4,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.branch_code)+" ")]),_c('td',[_c('inner_data',{attrs:{"headers":_vm.headers2,"items":item.office_rental_sec_deposits,"status":4},on:{"data":function($event){return _vm.initialize_data()}}})],1)])]}}])})],1),_c('v-tab-item',[_c('h2',[_vm._v("Total: P "+_vm._s(_vm.total_amount5))]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.data_items5,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.branch_code)+" ")]),_c('td',[_c('inner_data',{attrs:{"headers":_vm.headers2,"items":item.office_rental_sec_deposits,"status":5},on:{"data":function($event){return _vm.initialize_data()}}})],1)])]}}])})],1)],1)],1)],1)],1),_c('snack-bar-dialog',{attrs:{"snackbar_flag":this.snackbar,"color":this.snackbar_color,"snackbar_text":this.snackbar_text}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }