<template>
  <div>
    <v-data-table dense
                  :headers="headers"
                  :items="items"
                  disable-pagination
                  hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ item.bldg_owner }}
          </td>
          <td>
            {{ item.no_of_months }}
          </td>
          <td>
            {{ formatPrice(item.amount) }}
          </td>
          <td>
            {{
            (item.request_data.cv!=null?'CV#'+item.request_data.cv.voucher_no:(item.request_data.ckv!=null?'CKV#'+item.request_data.ckv.voucher_no:(item.request_data.ftv!=null?'FTV#'+item.request_data.ftv.voucher_no:'')))
            }}
          </td>
          <td>
            {{ item.remarks }}
          </td>
          <td v-if="status===1 && (employee_id===1||employee_id===2||employee_id===187)">
            <v-icon
              class="mr-2"
              color="warning"
              @click="dissolve_data(item.id,2)"
            >
              {{icons.mdiCastOff}}
            </v-icon>
            <v-icon
              class="mr-2"
              color="info"
              @click="update_data(item.id,3)"
            >
              {{icons.mdiCashRefund}}
            </v-icon>
            <v-icon
              class="mr-2"
              color="success"
              @click="consume_data(item.id,5,item.branch_id)"
            >
              {{icons.mdiShieldCheck}}
            </v-icon>
            <v-icon
              class="mr-2"
              color="info"
              @click="edit_bldg_owner(item.id,item.bldg_owner,item)"
            >
              {{icons.mdiPencil}}
            </v-icon>
          </td>
          <!--          <td v-if="status===3">-->
          <!--            <v-icon-->
          <!--              class="mr-2"-->
          <!--              color="info"-->
          <!--              @click="update_data(item.id,4)"-->
          <!--            >-->
          <!--              {{icons.mdiCashRefund}}-->
          <!--            </v-icon>-->
          <!--          </td>-->
        </tr>
      </template>
    </v-data-table>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
    <v-dialog v-model="is_dissolve" persistent max-width="50%">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">DISSOLVE</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>

          <v-col cols="12">
            <v-text-field
              v-model="reason"
              label="What is the reason?"
              dense
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-show="alert_customize">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message_customize}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
        </v-card-text>
      </v-card>
      <v-btn color="primary" @click="update_data(idd,statuss)"> Save Changes</v-btn>
      <v-btn color="error" @click="is_dissolve=false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="OfficeRental" persistent max-width="80%">
      <office-rental
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
      ></office-rental>
      <v-btn color="error" @click="OfficeRental = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="EditBldgOwner" persistent max-width="80%">
      <EditBldgOwner
        v-on:data="on_close_description_edit_dialog"
        :key="this.key"
        :idd="this.idd"
        :bldg_owner="this.bldg_owner"
        :num_depo="this.num_depo"
        :rem="this.rem"
      ></EditBldgOwner>
      <v-btn color="error" @click="EditBldgOwner = false"> Close</v-btn>
    </v-dialog>
  </div>
</template>

<script>
  import {
    mdiCastOff,
    mdiCashRefund,
    mdiShieldCheck,
    mdiPencil,
  } from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import OfficeRental from "@/views/file_interface/create/request/components/OfficeRental";
  import EditBldgOwner from "@/views/input_interface/security_deposits/components/EditBldgOwner";

  export default {
    components: {
      snackBarDialog,
      OfficeRental,
      EditBldgOwner,
    },
    props: {
      headers: Array,
      items: Array,
      status: Number,
    },
    setup() {
      return {
        key: 0,
        idd: 0,
        statuss: 0,
        branch_id: 0,
        bldg_owner: '',
        num_depo: 0,
        rem: '',
        reason: null,
        is_dissolve: false,
        alert_customize: false,
        OfficeRental: false,
        EditBldgOwner: false,
        alert_message_customize: '',
        icons: {
          mdiCastOff,
          mdiCashRefund,
          mdiShieldCheck,
          mdiPencil,
        },
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('office_rental_security_deposits', ['update_status', 'update_bldg_owner']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      dissolve_data(id, status) {
        this.is_dissolve = true
        this.idd = id
        this.statuss = status
        this.reason = null
      },
      consume_data(id, status, branch_id) {
        this.OfficeRental = true
        this.idd = id
        this.statuss = status
        this.branch_id = branch_id
        this.reason = null
      },
      edit_bldg_owner(id, owner, value) {
        this.EditBldgOwner = true
        this.bldg_owner = owner
        this.num_depo = value.no_of_months
        this.rem = value.remarks
        this.idd = id
      },
      on_close_description_edit_dialog(bldg_owner, no_of_months, remarks, id) {
        this.EditBldgOwner = false
        const data = new FormData()
        data.append('id', id);
        data.append('bldg_owner', bldg_owner);
        data.append('no_of_months', no_of_months);
        data.append('remarks', remarks);
        this.update_bldg_owner(data)
          .then(() => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: 'SUCCESSFULLY DATA UPDATED!!!',
            })
            this.$emit('data', true)
          })
          .catch(error => {
            console.log(error)
          });
      },
      on_close_description_dialog(value) {
        this.reason = value
        this.update_data(this.idd, this.statuss)
        this.is_dissolve = false
        this.OfficeRental = false
      },
      update_data(id, status) {
        this.is_dissolve = false
        this.OfficeRental = false
        const data = new FormData()
        data.append('id', id);
        data.append('status', status);
        data.append('remarks', this.reason);
        this.update_status(data)
          .then(() => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: 'SUCCESSFULLY DATA UPDATED!!!',
            })
            this.$emit('data', true)
          })
          .catch(error => {
            console.log(error)
          });
      }
    },
  }
</script>
