<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SECURITY DEPOSITS (OFFICE/CHAPEL RENTAL)</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12 class="mx-2">
          <v-tabs
            v-model="tab"
            show-arrows
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.title"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <!-- tabs item -->
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <h2>Total: P {{total_amount}}</h2>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items"
                            disable-pagination
                            hide-default-footer
              >
                <template v-slot:top>
                  LEGENDS -
                  (DISSOLVE-
                  <v-icon
                    class="mr-2"
                    color="warning"
                  >
                    {{icons.mdiCastOff}}
                  </v-icon>
                  ),
                  (FOR REFUND-
                  <v-icon
                    class="mr-2"
                    color="info"
                  >
                    {{icons.mdiCashRefund}}
                  </v-icon>
                  ),
                  (CONSUMED-
                  <v-icon
                    class="mr-2"
                    color="success"
                  >
                    {{icons.mdiShieldCheck}}
                  </v-icon>
                  ),
                  (EDIT BUILDING OWNER-
                  <v-icon
                    class="mr-2"
                    color="info"
                  >
                    {{icons.mdiPencil}}
                  </v-icon>
                  )
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.branch_code }}
                    </td>
                    <td>
                      <inner_data
                        :headers="headers2"
                        :items="item.office_rental_sec_deposits"
                        :status="1"
                        v-on:data="initialize_data()"
                      ></inner_data>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <h2>Total: P {{total_amount2}}</h2>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items2"
                            disable-pagination
                            hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.branch_code }}
                    </td>
                    <td>
                      <inner_data
                        :headers="headers2"
                        :items="item.office_rental_sec_deposits"
                        :status="2"
                        v-on:data="initialize_data()"
                      ></inner_data>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <h2>Total: P {{total_amount3}}</h2>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items3"
                            disable-pagination
                            hide-default-footer
              >
                <!--                <template v-slot:top>-->
                <!--                  LEGENDS - -->
                <!--                  (REFUNDED- -->
                <!--                  <v-icon-->
                <!--                    class="mr-2"-->
                <!--                    color="info"-->
                <!--                  >-->
                <!--                    {{icons.mdiCashRefund}}-->
                <!--                  </v-icon>-->
                <!--                  )-->
                <!--                </template>-->
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.branch_code }}
                    </td>
                    <td>
                      <inner_data
                        :headers="headers2"
                        :items="item.office_rental_sec_deposits"
                        :status="3"
                        v-on:data="initialize_data()"
                      ></inner_data>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <h2>Total: P {{total_amount4}}</h2>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items4"
                            disable-pagination
                            hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.branch_code }}
                    </td>
                    <td>
                      <inner_data
                        :headers="headers2"
                        :items="item.office_rental_sec_deposits"
                        :status="4"
                        v-on:data="initialize_data()"
                      ></inner_data>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <h2>Total: P {{total_amount5}}</h2>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items5"
                            disable-pagination
                            hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.branch_code }}
                    </td>
                    <td>
                      <inner_data
                        :headers="headers2"
                        :items="item.office_rental_sec_deposits"
                        :status="5"
                        v-on:data="initialize_data()"
                      ></inner_data>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiGoogleCirclesCommunities, mdiCastOff,
    mdiCashRefund,
    mdiShieldCheck,
    mdiPencil,
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import inner_data from './components/Data'

  const initialState = () => {
    return {
      data_items: [],
      data_items2: [],
      data_items3: [],
      data_items4: [],
      data_items5: [],
      total_amount: '0',
      total_amount2: '0',
      total_amount3: '0',
      total_amount4: '0',
      total_amount5: '0',
      headers: [
        {text: 'Branch', value: 'last_name', sortable: false},
        {text: '', value: 'last_name', sortable: false},
      ],
      headers2: [
        {text: 'Bldg. Owner', value: 'last_name', sortable: false, width: 200},
        {text: 'No. of Deposits', value: 'last_name', sortable: false},
        {text: 'Amount', value: 'last_name', sortable: false},
        {text: 'Voucher #', value: 'last_name', sortable: false},
        {text: 'Remarks', value: 'last_name', sortable: false, width: 500},
        {text: '', value: 'last_name', sortable: false},
      ],
      tab: '',
      tabs: [
        {title: 'ACTIVE', icon: mdiGoogleCirclesCommunities},
        {title: 'DISSOLVE', icon: mdiGoogleCirclesCommunities},
        {title: 'FOR REFUND', icon: mdiGoogleCirclesCommunities},
        {title: 'REFUNDED', icon: mdiGoogleCirclesCommunities},
        {title: 'CONSUMED', icon: mdiGoogleCirclesCommunities},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
      inner_data,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCastOff,
          mdiCashRefund,
          mdiShieldCheck,
          mdiPencil,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('office_rental_security_deposits', ['list_of_security_deposit_office_rental']),
      initialize_data() {
        this.list_of_security_deposit_office_rental()
          .then(response => {
            this.data_items = response.data[0].active
            var tto = 0;
            this.data_items.forEach(function (item) {
              item.office_rental_sec_deposits.forEach(function (item) {
                tto += parseFloat(item.amount)
              })
            });
            this.total_amount = this.formatPrice(tto)

            this.data_items2 = response.data[0].dissolve
            var tto2 = 0;
            this.data_items2.forEach(function (item) {
              item.office_rental_sec_deposits.forEach(function (item) {
                tto2 += parseFloat(item.amount)
              })
            });
            this.total_amount2 = this.formatPrice(tto2)

            this.data_items3 = response.data[0].for_refund
            var tto3 = 0;
            this.data_items3.forEach(function (item) {
              item.office_rental_sec_deposits.forEach(function (item) {
                tto3 += parseFloat(item.amount)
              })
            });
            this.total_amount3 = this.formatPrice(tto3)

            this.data_items4 = response.data[0].refunded
            var tto4 = 0;
            this.data_items4.forEach(function (item) {
              item.office_rental_sec_deposits.forEach(function (item) {
                tto4 += parseFloat(item.amount)
              })
            });
            this.total_amount4 = this.formatPrice(tto4)

            this.data_items5 = response.data[0].consumed
            var tto5 = 0;
            this.data_items5.forEach(function (item) {
              item.office_rental_sec_deposits.forEach(function (item) {
                tto5 += parseFloat(item.amount)
              })
            });
            this.total_amount5 = this.formatPrice(tto5)
          })
          .catch(error => {
            console.log(error)
          })

      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
    },
  }
</script>
